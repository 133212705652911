<ngx-spinner>
    <p style="font-size: 20px; color: white; text-align: center;">Espere un momento...</p>
</ngx-spinner>
<app-wait-for-medic *ngIf="!medicArrived"></app-wait-for-medic>
<div id="fullvideo" #fullvideo></div>
<div id="minivideo" #minivideo></div>
<div id="footer" *ngIf="showButtons">
    <app-controls [cid]="cid" [m]="isMedic" [enableHangup]="activeHangup"></app-controls>
    <aside>
        <p>Navegador recomendado</p>
        <img src="../../../assets/img/google-chrome.png" alt="Logo de Google Chrome">
    </aside>
    <app-timer *ngIf="showTimer" (enableBtn)="enableHangup($event)"></app-timer>
</div>