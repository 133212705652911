import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Config } from '../config'
import { roomDTO } from '../models/RoomDTO.model'
import { VideoCallStadisticsModel } from '../models/VideoCallStadistics.model'

@Injectable({
  providedIn: 'root'
})
export class VideoconsultaBackendService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // Endpoints generales
  checkCid(conferenceId: number) {
    return this.httpClient.get(
      Config.baseURL +
      Config.videoConsultaEndpoints.cid +
      conferenceId, { responseType: 'text', observe: 'response', reportProgress: true }
    )
  }

  getTokboxCredentials(conferenceId: number, roomDTO: roomDTO) {
    return this.httpClient.post(
      Config.baseURL +
      Config.videoConsultaEndpoints.room +
      conferenceId,
      roomDTO, { reportProgress: true }
    )
  }

  blacklistCid(conferenceId: number) {
    return this.httpClient.post(
      Config.baseURL +
      Config.videoConsultaEndpoints.cid,
      { cid: conferenceId }, { observe: 'response' }
    )
  }

  // Endpoints de estadisticas
  statsVc(videoCallStatsDTO: VideoCallStadisticsModel, isMedic: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }

    return this.httpClient.post(
      Config.baseURL + Config.easyDocEndpoints.statsvc + `${isMedic}`,
      videoCallStatsDTO,
      httpOptions
    )
  }

  statsVcFinalized(videoCallStatsDTO: VideoCallStadisticsModel, isMedic: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }

    return this.httpClient.post(
      Config.baseURL + Config.easyDocEndpoints.finalizedVideoCall + `${isMedic}`,
      videoCallStatsDTO,
      httpOptions
    )
  }

  statsByCid(cid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }

    return this.httpClient.get(
      Config.baseURL + Config.easyDocEndpoints.statsBycid + cid,
      httpOptions
    )
  }
}
