import { Injectable } from '@angular/core'
import * as OT from '@opentok/client'
import { TokboxCredentials } from 'src/app/types/TokboxCredentials'

@Injectable({
  providedIn: 'root'
})
export class OpentokService {

  session: OT.Session
  publisher: OT.Publisher
  token: string
  
  constructor(){
  }

  get OT(){
    return OT
  }

  initSession({apiKey, sessionId, token}: TokboxCredentials): OT.Session {
    this.token = token
    this.session = this.OT.initSession(apiKey, sessionId)
    return this.session
  }

  suscribe(stream: OT.Stream, targetElement: HTMLElement) {
    this.session.subscribe(
      stream,
      targetElement,
      {
        insertMode: 'append',
        width: "100%",
        height: "100%",
        style: { buttonDisplayMode: "off", audioLevelDisplayMode: "off" },
        fitMode: 'contain'
      },
      err => {
        if (err) {
          console.log(err)
        }
      }
    )
  }

  initPublisher(targetElement: HTMLElement, isMedic: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.publisher = OT.initPublisher(
        targetElement,
        {
          insertMode: "append",
          width: '100%',
          height: '100%',
          style: { buttonDisplayMode: "off", audioLevelDisplayMode: "off" },
          publishAudio: !isMedic && true,
          publishVideo: true
        },
        err => {
          if (err) reject(err)
          else resolve()
        }
      )
    })
  }

  connect(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if (err) reject(err)
        else {
          this.session.publish(this.publisher, (err) => {
            if (err) reject(err)
            else resolve()
          })
        }
      })
    })
  }
}
