import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { OpentokService } from 'src/app/services/opentok.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ControlsComponent implements OnInit {

  @Input('cid') cid: number
  @Input('m') m: number
  @Input('enableHangup') enable: boolean

  micOpen: boolean
  camOpen = true

  constructor(
    private _opentok: OpentokService,
    private _dialog: DialogService
  ) { }

  ngOnInit(): void {
    this.micOpen = this.m ? false : true
  }

  mute() {
    this.micOpen = !this.micOpen
    this._opentok.publisher.publishAudio(this.micOpen)
  }

  show() {
    this.camOpen = !this.camOpen
    this._opentok.publisher.publishVideo(this.camOpen)
  }

  hangup() {
    this._dialog.createDialog('finish', this.cid, this.m)   
  }
}
