<div class="popupWrapper">
  <div class="info">
    <img src="../../../assets/img/logo-small.png" alt="Logo de URG">
    <h2>{{title}}</h2>
    <p>{{body}}</p>
    <p *ngIf="link">Si no sabes como activar los permisos de tu navegador, te dejamos una <a [href]="link" target="_blank">guía de Google</a> de como hacerlo.</p>
  </div>
  <div *ngIf="btns && btns.length === 1" class="btns one">
    <button (click)="closePopup()">{{btns[0]}}</button>
  </div>
  <div *ngIf="btns && btns.length > 1" class="btns">
    <button (click)="closePopup()">{{btns[0]}}</button>
    <button (click)="finalizarVC()">{{btns[1]}}</button>
  </div>
</div>
<ngx-spinner>
  <p style="font-size: 20px; color: white; text-align: center;">Espere un momento...</p>
</ngx-spinner>