import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: '<div class="header-content"><div class="content-btn"><div class="img-logo"></div></div></div>',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  constructor() {}
}
