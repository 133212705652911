import { PopupBody } from "../types/PopupBody";

const closeFinishBtns = [
  'Volver a la llamada',
  'Finalizar video consulta'
]

const cidAndIsMedic = {
  cid: 0,
  isMedic: 0,
}

const waitToReconnect = {
  bodyS: 'El socio podría volver a conectarse. En caso de que no se conecte dentro de unos minutos, presione el botón finalizar video consulta.',
  bodyM: 'El médico podría volver a conectarse. En caso de que no se conecte dentro de unos minutos, presione el botón finalizar video consulta.',
}

export const WaitMedicPopupBody: PopupBody = {
  ...cidAndIsMedic,
  title: 'El médico se desconectó de la llamada',
  body: waitToReconnect.bodyM,
  btns: closeFinishBtns
}

export const WaitSocioPopupBody: PopupBody = {
  ...cidAndIsMedic,
  title: 'El socio se desconectó de la llamada',
  body: waitToReconnect.bodyS,
  btns: closeFinishBtns
}

export const networkMedicPopupBody: PopupBody = {
  ...cidAndIsMedic,
  title: 'El médico perdió su conexión a internet',
  body: waitToReconnect.bodyM,
  btns: closeFinishBtns
}

export const networkSocioPopupBody: PopupBody = {
  ...cidAndIsMedic,
  title: 'El socio perdió su conexión a internet',
  body: waitToReconnect.bodyS,
  btns: closeFinishBtns
}

export const FinishPopupBody: PopupBody = {
  ...cidAndIsMedic,
  title: 'Vas a finalizar la videoconsulta',
  body: '¿Estás seguro de que quieres finalizar la video consulta?',
  btns: closeFinishBtns
}

export const PermissionsPopupBody: PopupBody = {
  ...cidAndIsMedic,
  title: 'No tienes permisos para usar la cámara y el micrófono',
  body: 'Para poder realizar una video consulta necesitas tener habilitados los permisos del navegador, una vez activos recarga el navegador para continuar a la video consulta.',
  link: 'https://support.google.com/chrome/answer/2693767?hl=es-419&co=GENIE.Platform%3DDesktop&oco=1'
}