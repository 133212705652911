import { Component } from '@angular/core';

@Component({
  selector: 'app-wait-for-medic',
  templateUrl: './wait-for-medic.component.html',
  styleUrls: ['./wait-for-medic.component.css']
})
export class WaitForMedicComponent {

  constructor() { }

}
