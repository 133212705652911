import { Injectable } from '@angular/core'
import { DeviceService } from './device.service'
import { formatMedicName, getSpecificDateType } from '../utils/utils'
import { VideoconsultaBackendService } from './videoconsulta-backend.service'
import { ClientData } from '../types/ClientData'
import { VideoCallStadisticsModel } from '../models/VideoCallStadistics.model'
import { SendStadisticsExtras } from '../types/SendStadisticsExtras'

@Injectable({
  providedIn: 'root'
})
export class MangeStadisticsService {

  cid: number
  isMedic: number
  videoStadisticsDTO: VideoCallStadisticsModel

  constructor(
    private _device: DeviceService,
    private _videoconsulta: VideoconsultaBackendService
  ) { }

  initStadistics(cid: number, isMedic: number): void {
    this.videoStadisticsDTO = new VideoCallStadisticsModel()
    this.cid = cid
    this.isMedic = isMedic
  }

  buildStatistics(data: ClientData): VideoCallStadisticsModel {
    const browser = this._device.getBrowser()
    const device = this._device.getDeviceAndOs()
    const attentionDate = getSpecificDateType("DD/MM/YYYY")
    const enteredAt = getSpecificDateType()

    this.videoStadisticsDTO.attentionDate = attentionDate
    this.videoStadisticsDTO.conferenceId = this.cid
    
    if (this.isMedic == 1) {
      this.videoStadisticsDTO.medicName = formatMedicName(data.medicName)
      this.videoStadisticsDTO.medicEnteredAt = enteredAt
      this.videoStadisticsDTO.medicBrowser = browser
      this.videoStadisticsDTO.medicDevice = device
      this.videoStadisticsDTO.medicId = data.medicId
      this.videoStadisticsDTO.patientDNI = data.patientDNI
      this.videoStadisticsDTO.origen = data.origen
    } else if (this.isMedic == 0) {
      this.videoStadisticsDTO.patientEnteredAt = enteredAt
      this.videoStadisticsDTO.patientBrowser = browser
      this.videoStadisticsDTO.patientDevice = device
      this.videoStadisticsDTO.origensocio = data.origensocio
    }

    return this.videoStadisticsDTO
  }

  buildStatisticsFinalized(streamfinished: 'Medico' | 'Socio'): VideoCallStadisticsModel {
    const currentDate = getSpecificDateType()

    this.videoStadisticsDTO.streamfinished = streamfinished

    if (this.isMedic == 1) {
      this.videoStadisticsDTO.medicFinalizedAt = currentDate
    } else {
      this.videoStadisticsDTO.patientFinalizedAt = currentDate
    }

    return this.videoStadisticsDTO
  }

  buildStatisticsReason(reasonMedic: string, reasonPatient: string): VideoCallStadisticsModel {
    this.videoStadisticsDTO.reasondisconnectionmedic = reasonMedic
    this.videoStadisticsDTO.reasondisconnectionpatient = reasonPatient

    return this.videoStadisticsDTO
  }

  sendStatistics(reason: 'inicio' | 'final' | 'reason', extras: SendStadisticsExtras): Promise<void> {
    const medic = this.isMedic ? 'true' : 'false'
    return new Promise((resolve, reject) => {
      if (reason == "inicio") {
        const stadistics = this.buildStatistics(extras.data)
        this._videoconsulta.statsVc(stadistics, medic).subscribe({
          next: () => resolve(),
          error: (err) => reject(err)
        })
      } else if (reason == "final") {
        const stadistics = this.buildStatisticsFinalized(extras.streamfinished)
        this._videoconsulta.statsVcFinalized(stadistics, medic).subscribe({
          next: () => resolve(),
          error: (err) => {
            if (err) console.log(err)
            reject(err)
          }
        })
      } else if (reason == "reason") {
        const stadistics = this.buildStatisticsReason(extras.reasonMedic, extras.reasonPatient)
        this._videoconsulta.statsVcFinalized(stadistics, medic).subscribe({
          next: () => resolve(),
          error: (err) => {
            if (err) console.log(err)
            reject(err)
          }
        })
      }
    })
  }
}
