import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoCallStadisticsModel } from 'src/app/models/VideoCallStadistics.model';
import { VideoconsultaBackendService } from 'src/app/services/videoconsulta-backend.service';
import { getSpecificDateType } from 'src/app/utils/utils';

@Component({
  selector: 'app-finalizado',
  templateUrl: './finalizado.component.html',
  styleUrls: ['./finalizado.component.css']
})
export class FinalizadoComponent implements OnInit {

  cid: string;
  isMedic: string;
  fechaMedic: string;
  fechaPaciente: string;

  constructor(
    private _videoconsulta: VideoconsultaBackendService,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.cid = params["cid"];
      this.isMedic = params["m"];
    });
    this.getStadisticsByCid();
  }

  getStadisticsByCid() {
    this._videoconsulta.statsByCid(this.cid).subscribe({
      next: (resp: VideoCallStadisticsModel) => {
        if (this.isMedic == "0" && resp.patientFinalizedAt != null) {
          this.fechaPaciente = resp.patientFinalizedAt;
        } else {
          this.fechaPaciente = getSpecificDateType("YYYY-MM-DD HH:mm:ss")
        }

        if (this.isMedic == "1" && resp.medicFinalizedAt != null) {
          this.fechaMedic = resp.medicFinalizedAt;
        } else {
          this.fechaMedic = getSpecificDateType("YYYY-MM-DD HH:mm:ss")
        }
      }, 
      error: (err) => err && console.log(err)
    })
  }

}
