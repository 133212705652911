import { Component } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-tyc',
  templateUrl: './tyc.component.html',
  styles: [
    '.tyc { background-color: #ffffff; cursor: pointer; position: absolute; top: 0; left: 0; width: 100%; padding: 10px 40px;}',
    '.closeIconWrapper { position: fixed; right: 10px; top: 10px; background-color: #00b4d5; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; border-radius: 50%; z-index: 1000000000000000; }',
    '.closeIconWrapper img { width: 90%; height: 90%; object-fit: cover; }'
  ]
})
export class TycComponent {

  constructor(
    private _dialog: DialogService
  ) { }

  close() {
    this._dialog.closeDialog()
  }

}
