<div id="controls">
    <button (click)="hangup()" [disabled]="!enable" [ngClass]="{'active': enable}" id="hangup">
        <div class="tooltip"><p>Al finalizar, recuerde colgar la videoconsulta</p></div>
        <img src="../../../assets/img/hangup.png" alt="Icono colgar">
    </button>
    <button (click)="show()" [ngClass]="{'disable': !camOpen}">
        <img src="../../../assets/img/cam.png" alt="Icono camara">
    </button>
    <button (click)="mute()" [ngClass]="{'disable': !micOpen}">
        <img src="../../../assets/img/mic.png" alt="Icono microfono">
    </button>
</div>