<div class="closeIconWrapper" (click)="close()">
  <img src="../../../assets/icon/iconequis.png" alt="Close icon">
</div>
<div class="tyc big" style="z-index: 1000000000;">
  <h2>Términos y Condiciones de uso del Servicio de Videoconsultas de URG</h2>
  <p>
    URG pone a tu disposición un servicio de videoconsultas que te permite acceder a una opinión médica en forma
    rápida y sencilla de manera virtual.<br>
    <br>
    El acceso a éste servicio implica aceptar éstos términos y condiciones:
  </p>
  <ol>
    <li>Quienes accedan al servicio deben ser socios de URG y encontrarse al día con el pago de la cuota.</li>
    <li>Podrán realizarse cambios en los Términos y Condiciones en cualquier momento. Cuando se introduzcan
      estos cambios se publicará una nueva copia. Usted reconoce y acepta que el uso del Servicio luego de la
      fecha de publicación de los Términos y Condiciones, o sus modificaciones, se considerará una aceptación
      lisa y llana de los actualizados. Recomendamos efectúe una revisión periódica para mantenerse informado
      acerca de la posible existencia de cambios. Recuerde que cada vez que acceda al enlace para realizar la
      Vídeo Consulta implica que ha leído, comprendido y aceptado estos Términos y Condiciones.</li>
    <li>Para poder acceder a las prestaciones que brinda el Sitio, deberá ser mayor de edad de conformidad a la
      ley Argentina, responsabilizándose por la exactitud de la información personal que nos brinde a través
      del uso del sitio. En el caso de que la Información que nos brinde sea errada o incompleta,
      imposibilitando la efectiva comprobación e identificación de usted como usuario, URG tendrá el derecho
      de cancelar el servicio, quedando exento de cualquier responsabilidad o resarcimiento hacia usted.</li>
    <li>Ud. comprende y acepta que será el responsable por el uso de tal servicio a través terceras personas
      ajenas a su grupo familiar. En el supuesto de que Ud. detecte un uso indebido del servicio en su nombre
      deberá comunicarlo inmediatamente al 0810-444-351111. Ud. podrá utilizar el sitio en relación a los
      menores de edad u otras personas bajo su tutela sobre los que ejerza la representación legal y/o tenga
      adheridos a su plan médico debiendo informar dicha situación en el momento de solicitar el servicio.
    </li>
    <li>Usted acepta ser el único responsable de toda la actividad que tenga lugar en el sitio. URG en ningún
      momento es, o puede ser, responsable de pérdidas o daños que se deriven de un uso no autorizado del
      servicio.</li>
    <li>La Vídeo Consulta no reemplaza de ningún modo la consulta presencial. Ante cualquier duda o inquietud
      Ud. tiene a su disposición la posibilidad de solicitar una visita médica. En ningún caso deberá
      utilizarse éste servicio para una emergencia que implique riesgo de vida o emergencias psiquiátricas.
      Para ello debe comunicarse con nuestro servicio de Urgencias al 0810-444-351111. Considerando que Ud.
      elige voluntariamente éste servicio, encontrándose siempre a su disposición la posibilidad de recibir
      asistencia de forma presencial, URG deslinda toda responsabilidad por las consecuencias derivadas de su
      decisión de acceder a éste servicio.</li>
    <li>Entiende y acepta que al tratarse de una Vídeo Consulta la información con la que contará el médico
      puede ser incompleta, por lo que su respuesta se limitará a una opinión médica y no una indicación
      médica concreta respecto a las acciones terapéuticas a adoptar. Asimismo Ud. acepta que es el único
      responsable de la veracidad de la información transmitida.</li>
    <li>Ante su solicitud de Vídeo Consulta , tanto el operador de urgencias que reciba su requerimiento como el
      médico asignado para evacuarla pueden decidir -de acuerdo a su criterio- que ésta no se lleve a cabo y
      en consecuencia asignar una visita médica en su domicilio.</li>
    <li>Ud. acepta que URG podrá acceder a nuestros registros relativos al uso del servicio a fin de poder
      brindarle una opinión informada respecto a su solicitud. La información y/o documentación que sea
      transmitida a través del servicio de Vídeo Consulta será recopilada y almacenada en nuestros servidores
      ubicados en Moreno 949 - Rosario. El titular de los datos personales no se encuentra obligado a
      proporcionar datos sensibles. Asimismo, el titular se responsabiliza por la exactitud de los datos
      proporcionados. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a
      los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés
      legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25.326. La DIRECCIÓN
      NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de control de la Ley N° 25.326, tiene la atribución
      de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas
      sobre protección de datos personales. Considerando que es imprescindible obtener algunos datos
      personales suyos para poder brindarle el servicio de Vídeo Consulta , en caso de desacuerdo no debe
      utilizarlo.</li>
    <li>Ud. comprende que en el servicio de Vídeo Consulta se utiliza como medio de comunicación un servicio de
      internet o también telefónica por el que URG no puede ni debe responder. En tal sentido, Ud. comprende y
      acepta que URG no puede garantizar la posibilidad ni la calidad de la comunicación para brindarle un
      adecuado servicio. Asimismo, si bien URG llevará a cabo todos los esfuerzos razonables para asegurar la
      confidencialidad de la información que sea transmitida al tratarse de redes públicas sobre las que
      ningún control ostenta.</li>
    <li>Las prestaciones ofrecidas por el uso del Servicio se prestan de la forma establecida por URG. En
      particular, URG no le manifiesta ni garantiza que: o el uso del Sitio o las comunicaciones telefónicas
      cumplirá sus requisitos y expectativas, o el uso del Sitio o comunicaciones telefónicas será
      ininterrumpido, puntual, seguro o libre de errores, y o la información que obtenga como consecuencia del
      uso del Sitio y de las comunicaciones telefónicas será correcta. No serán de aplicación al Sitio ninguna
      condición o garantía (incluyendo cualquier garantía implícita de calidad y adecuación a un fin concreto
      o a una descripción) excepto en la medida de lo expresamente estipulado en los presentes Términos y
      Condiciones; y URG en ningún caso será responsable por los inconvenientes que pudieran surgir en la
      calidad e interrupción de la conectividad del servicio.</li>
    <li>Nada de lo estipulado en los presentes Términos y Condiciones excluirá o limitará la responsabilidad de
      URG por pérdidas que no pueda ser legalmente excluidas o limitadas en virtud de la legislación
      aplicable. URG, sus directores, ejecutivos, empleados, filiales, agentes, contratistas, licenciantes o
      cedentes no serán responsables frente a Usted por: o pérdidas indirectas o derivadas en que Usted pueda
      incurrir; o pérdidas o daños en que Usted pueda incurrir a consecuencia de: o la credibilidad que Usted
      otorgue a la exhaustividad, precisión o existencia de cualquier material, publicitario o no; o cualquier
      cambio que URG introduzca en relación con el Servicio, o cualquier cese permanente o temporal del mismo
      (o de cualquiera de sus funciones); o la eliminación, corrupción o fallo de almacenamiento de cualquier
      Archivo de Usuario u otros datos de comunicación mantenidos o transmitidos a través del uso del
      Servicio; o su falta de suministro a URG de información precisa y correcta; o su falta de mantenimiento
      de la seguridad y confidencialidad de su contraseña o información de cuenta de usuario; o pérdidas o
      daños provocados o relacionados por uso incorrecto del Servicio. Las limitaciones a la responsabilidad
      de URG frente a Usted serán aplicables con independencia de que URG haya sido advertido o debiera haber
      sido advertido de la posibilidad de que se produzcan dichas pérdidas. Ni URG ni sus directores,
      ejecutivos, empleados, filiales, agentes, contratistas, licenciantes o cedentes serán responsables, en
      ningún caso, por daños o perjuicios sean estos directos, indirectos, punitivos, o extraordinarios, como
      consecuencia del uso del Servicio, o por un reclamo relacionado con el uso del Servicio, incluyendo
      entre otros, daños de cualquier índole, errores u omisiones de contenido, o daños y perjuicios de
      cualquier índole, ocasionados por el uso de contenido enviado, transmitido o puesto a disposición de
      alguna forma por medio del uso del Servicio, aún cuando se haya emitido un aviso al respecto. No se
      considerará que ninguna de las partes haya incurrido en incumplimiento ni será responsable frente a la
      otra, por la omisión en el cumplimiento de cualquiera de sus obligaciones no pecuniarias derivadas del
      presente, por el plazo y en la medida en que dicho incumplimiento resulte de caso fortuito o fuerza
      mayor, actos u omisiones de la otra parte o terceros, desastres naturales, disturbios, guerras,
      desórdenes civiles o cualesquiera otras causas que se encuentren fuera del control razonable de las
      partes (incluyendo fallas o fluctuaciones en la energía eléctrica, calor, luz, aire acondicionado o
      equipamiento y líneas de telecomunicaciones y/o mal funcionamiento de los software exigidos para brindar
      el servicio) y que no hayan podido ser prevenidas haciendo los esfuerzos razonables.</li>
    <li>Estos Términos y Condiciones constituyen el acuerdo completo entre Usted y URG, y rigen el uso del
      Servicio, complementando cualquier otro acuerdo suscripto. Asimismo, se deja establecido que pueden
      aplicarse términos y condiciones adicionales a los presentes cuando se utilicen servicios asociados. Si
      alguna disposición del presente acuerdo fuese declarada inválida o sin efecto legal, dicha disposición
      se interpretará de conformidad con la legislación vigente de modo a reflejar lo que las Partes quisieron
      y entendieron al contratar, y las demás disposiciones permanecerán vigentes en todos sus aspectos. La no
      exigencia por parte de URG del cumplimiento de cualquier derecho o disposición de los Términos y
      Condiciones no constituirá una renuncia o dispensa a su cumplimiento ni a ninguna otra de las contenidas
      en estos Términos y Condiciones. Si alguna disposición del presente Contrato fuese declarada inválida
      por un tribunal competente, las demás disposiciones permanecerán vigentes en todos sus aspectos. URG no
      será responsable de cualquier falta de cumplimiento en cualquiera de sus obligaciones por causas que
      escapen a su control. Los Términos y Condiciones y todos los derechos y obligaciones relacionadas con
      este Contrato, como también su interpretación o significado de sus términos, serán regidos,
      interpretados y ejecutados de conformidad con la ley de la República Argentina, con expresa renuncia a
      cualquier otro fuero.</li>
  </ol>
</div>