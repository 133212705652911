import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

  @Output() enableBtn = new EventEmitter<boolean>()

  showSeconds: string = '00'
  showMinutes: string = '00'
  showHours: string = '00'
  showTime: string = '00:00:00'
  seconds: number = 0
  minutes: number = 0
  hours: number = 0

  constructor() { }

  ngOnInit() {
    setInterval(() => this.changeTime(), 1000)
  }

  changeTime() {
    if (this.seconds > 58) {
      this.seconds = 0
      this.minutes++
      this.showMinutes = this.minutes.toString().length === 1 ? `0${this.minutes}` : this.minutes.toString()
      this.showSeconds = '00'
    } else {
      this.seconds++
      this.showSeconds = this.seconds.toString().length === 1 ? `0${this.seconds}` : this.seconds.toString()
    }

    if (this.minutes > 58) {
      this.minutes = 0
      this.hours++
      this.showHours = this.hours.toString().length === 1 ? `0${this.hours}` : this.hours.toString()
      this.showMinutes = '00'
    }

    if (this.minutes === 1) {
      this.enableBtn.emit(true)
    }

    this.showTime = `${this.showHours}:${this.showMinutes}:${this.showSeconds}`
  }

}
