import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PopupComponent } from '../components/popup/popup.component';
import { FinishPopupBody, networkMedicPopupBody, networkSocioPopupBody, PermissionsPopupBody, WaitMedicPopupBody, WaitSocioPopupBody } from '../constants/constants';
import { TycComponent } from '../components/tyc/tyc.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialog: MatDialogRef<PopupComponent | TycComponent>

  constructor(
    private _matDialog: MatDialog
  ) { }

  createDialog(type: 'waitM' | 'waitS' | 'finish' | 'permissions' | 'netM' | 'netS' | 'tycs', cid: number = 0, isMedic: number = 0): void {
    if (type === 'tycs') {
      this.dialog = this._matDialog.open(TycComponent)
      return
    }

    let body = {}

    if (type === 'waitM') body = WaitMedicPopupBody
    else if (type === 'waitS') body = WaitSocioPopupBody
    else if (type === 'finish') body = FinishPopupBody
    else if (type === 'permissions') body = PermissionsPopupBody
    else if (type === 'netM') body = networkMedicPopupBody
    else if (type === 'netS') body = networkSocioPopupBody

    this.dialog = this._matDialog.open(PopupComponent, {
      width: '100%',
      height: '100%',
      data: {
        ...body,
        cid,
        isMedic
      }
    })
  }

  closeDialog(): void {
    this.dialog.close()
  }
}
