import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BienvenidaComponent } from './pages/bienvenida/bienvenida.component';
import { VideoconsultaComponent } from './pages/videoconsulta/videoconsulta.component';
import { FinalizadoComponent } from './pages/finalizado/finalizado.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { WaitForMedicComponent } from './components/wait-for-medic/wait-for-medic.component';
import { ControlsComponent } from './components/controls/controls.component';
import { FooterComponent } from './components/footer/footer.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialogModule, MatTooltipModule } from '@angular/material';
import { PopupComponent } from './components/popup/popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimerComponent } from './components/timer/timer.component'
import { NgxSpinnerModule } from 'ngx-spinner';
import { TycComponent } from './components/tyc/tyc.component';

@NgModule({
  declarations: [
    AppComponent,
    BienvenidaComponent,
    VideoconsultaComponent,
    FinalizadoComponent,
    NotFoundPageComponent,
    WaitForMedicComponent,
    ControlsComponent,
    FooterComponent,
    PopupComponent,
    TimerComponent,
    TycComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    MatTooltipModule
  ],
  entryComponents: [PopupComponent, TycComponent],
  providers: [DeviceDetectorService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
