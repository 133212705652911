import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private _device: DeviceDetectorService
  ) { }

  getBrowser() {
    return `${this._device.browser} ${this._device.browser_version}`
  };

  getDeviceAndOs() {
    const device = this._device.device
    const OS = ` ${this._device.os} ${this._device.os_version}`

    if (device == "Unknown") {
      return 'PC' + OS
    } else {
      return device + OS
    }
  }
}
