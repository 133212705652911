import { Params } from "@angular/router"
import * as moment from "moment"
import { ClientData } from "../types/ClientData"

/**
 * Extrae los parametros del objeto devuelto por queryParams y devuelve un objeto construido a partir de dichos parametros
 * 
 * @param {Params} params Objeto devuelto del metodo queryParams
 * @returns {{ conferenceId: number, isMedic: number, clientData: ClientData }} Parametros formateados dentro de un objeto
 */
export const extracParams = (params: Params): { conferenceId: number, isMedic: number, clientData: ClientData } => {
    let clientData: ClientData = {}
    
    const conferenceId = parseInt(params["cid"])
    const isMedic = parseInt(params["m"])
    
    if (isMedic == 1) {
        clientData.medicId = params['identificacionMedico'],
        clientData.patientDNI = params['numeroDeDocumento']
        clientData.medicName = params["nombreMedico"]
        clientData.origen = params["origen"]
    } else if (isMedic == 0) {
        clientData.origensocio = params["origensocio"]
    }

    return { conferenceId, isMedic, clientData }
}

/**
 * @param pattern - Formato de tipo fecha, hora, segundos ej. YYYY-DD-MM
 * @returns Fecha tipo String con el formato pasado desde el parametro.
 */
export const getSpecificDateType = (pattern?: string) => {
    if (pattern) return moment().format(pattern)
    else return moment().format()
}

/**
 * Toma el nombre del medico pasado por los params de la URL y lo transforma en una cadena de texto
 *
 * @param {string} name Nombre del medico de los params
 * @return {string}  Cadena de texto con el nombre formateado
 */
export const formatMedicName = (name: string): string => {
    const finalName = name.split("$")

    let formatedName: string = ''

    if (finalName.length === 1) return finalName[0]

    finalName.forEach((name, i) => {
        if (i >= finalName.length - 1) {
            formatedName += name
        } else {
            formatedName += name + ' '
        }
    })

    return formatedName
}