export class VideoCallStadisticsModel {
  attentionDate: string = '';
  conferenceId: number = 0;
  medicId: string = '';
  medicName: string = '';
  medicEnteredAt: string = '';
  medicBrowser: string = '';
  medicDevice: string = '';
  patientDNI: string = '';
  patientEnteredAt: string = '';
  patientBrowser: string = '';
  patientDevice: string = '';
  medicFinalizedAt: string = '';
  patientFinalizedAt: string = '';
  totalDuration: string = '';
  reconnectionMedic: string = '';
  reconnectionPatient: string = '';
  reasondisconnectionpatient : string = '';
  reasondisconnectionmedic : string = '';
  streamfinished : string = '';
  origen: string = '';
  origensocio: string = '';
}