import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { DialogService } from 'src/app/services/dialog.service'
import { VideoconsultaBackendService } from 'src/app/services/videoconsulta-backend.service'
import { ClientData } from 'src/app/types/ClientData'
import { extracParams } from 'src/app/utils/utils'

@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.css']
})
export class BienvenidaComponent implements OnInit {

  conferenceId: number
  isMedic: number
  clientParamsData: ClientData
  activeBtn: boolean = true

  constructor(
    private route: ActivatedRoute,
    private _videoconsulta: VideoconsultaBackendService,
    private router: Router,
    private _spinner: NgxSpinnerService,
    private _dialog: DialogService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const { conferenceId, isMedic, clientData } = extracParams(params)
      this.conferenceId = conferenceId
      this.isMedic = isMedic
      this.clientParamsData = clientData
    })

    if (this.conferenceId) {
      // Valido el conferenceID
      this._spinner.show()
      this._videoconsulta.checkCid(this.conferenceId).subscribe({
        next: (body) => {
          if (body.status === 200) {
            this._spinner.hide()
            if (this.isMedic == 1) {
              const { medicId, medicName, patientDNI, origen } = this.clientParamsData
              this.router.navigate(
                ["/videoconsulta"],
                { queryParams: { cid: this.conferenceId, m: this.isMedic, identificacionMedico: medicId, nombreMedico: medicName, numeroDeDocumento: patientDNI, origen } }
              )
            }
          }
        },
        error: err => {
          if (err.status === 400) {
            this._spinner.hide()
            this.router.navigate(
              ["/finalizado"],
              { queryParams: { cid: this.conferenceId, m: this.isMedic } })
          }
        }
      })
    }
  }

  checboxChange(event: Event) {
    this.activeBtn = (event.target as HTMLInputElement).checked
  }

  goToVC() {
    const { origensocio } = this.clientParamsData
    this.router.navigate(
      ["/videoconsulta"],
      { queryParams: { cid: this.conferenceId, m: this.isMedic, origensocio: origensocio } }
    )
  }

  openTycs() {
    this._dialog.createDialog('tycs')
  }

}
