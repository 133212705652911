<div class="waitingroom-content">
    <section class="header-WR ">
        <div class="logo">
            <img src="../../../assets/img/logo-small.png" alt="Logo URG">
        </div>
        <p class="waitRoom-title">Sala de espera</p>
    </section>
    <section class="container-slideWR">
        <div>
            <div class="gif-medico"></div>
            <h2 class="title-slideWR">Aguarde al médico por favor...</h2>
            <div style="text-align: center;">
                <img class="imgSlide-slideWR" src="../../../assets/img/slide-2.png">
            </div>
        </div>
    </section>
</div>