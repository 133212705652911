import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BienvenidaComponent } from './pages/bienvenida/bienvenida.component';
import { VideoconsultaComponent } from './pages/videoconsulta/videoconsulta.component';
import { FinalizadoComponent } from './pages/finalizado/finalizado.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';


const routes: Routes = [
  { path: 'bienvenido', component: BienvenidaComponent },
  { path: 'videoconsulta', component: VideoconsultaComponent },
  { path: 'finalizado', component: FinalizadoComponent },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
