import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'src/app/services/dialog.service';
import { MangeStadisticsService } from 'src/app/services/manage-stadistics.service';
import { OpentokService } from 'src/app/services/opentok.service';
import { VideoconsultaBackendService } from 'src/app/services/videoconsulta-backend.service';
import { PopupBody } from 'src/app/types/PopupBody';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  title: string
  body: string
  btns: string[]
  link: string

  constructor(
    private _dialog: DialogService,
    private _opentok: OpentokService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: PopupBody,
    private _videoconsulta: VideoconsultaBackendService,
    private _stadistics: MangeStadisticsService,
    private _spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    const {title, body, btns, link} = this.data
    this.title = title
    this.body = body
    this.btns = btns
    this.link = link
  }

  finalizarVC() {
    const { cid, isMedic } = this.data

    this._spinner.show()
    this._dialog.closeDialog()

    if (isMedic) {
      this._videoconsulta.blacklistCid(cid).subscribe({
        next: () => this.endCall('Medico', cid, isMedic),
        error: err => err && console.log(err)
      })
    } else this.endCall('Socio', cid, isMedic)
  }

  endCall(user: 'Medico' | 'Socio', cid: number, m: number): void {
    this._stadistics.sendStatistics('final', { streamfinished: user }).then(() => {
      this._opentok.session.disconnect()
      this._spinner.hide()
      this.router.navigate(
        ['/finalizado'],
        { queryParams: { cid, m } }
      )
    }).catch(err => err && console.log(err))
  }

  closePopup() {
    this._dialog.closeDialog()
  }

}
