<div class="mainWrapper">
  <div class="header-content">
      <div class="header-circle-container">
          <h1 class="title">Tu Video Consulta Médica ha finalizado</h1>
          <div class="sub-title">Muchas gracias por utilizar nuestro servicio</div>
      </div>
      <div class="circle-header"></div>
      <div>
          <div class="img-peole"></div>
          <div class="finalizacion">
              <div>Fecha y hora de finalización: </div>
              <div *ngIf="isMedic === '0'"> {{ fechaPaciente }}</div>
              <div *ngIf="isMedic === '1'"> {{ fechaMedic }}</div>
          </div>
      </div>
  </div>
  <app-footer></app-footer>
</div>