<ngx-spinner>
    <p style="font-size: 20px; color: white; text-align: center;">Espere un momento...</p>
</ngx-spinner>

<section class="header-content">
    <div class="header-circle-container">
        <h1 class="title">Bienvenida/o:</h1>
        <div class="sub-title">Tu Video Consulta Médica está por comenzar</div>
    </div>
    <div class="circle-header"></div>
    <div class="img-peole"></div>
</section>
<div class="header-content">

    <div class="content-btn">
        <div class="cbox">
            <input type="checkbox" id="chk-tyc" name="tyc" (change)="checboxChange($event)" checked>
            <label for="chk-tyc"></label>
        </div>
        <label>
            Acepto los <span class="show-tyc" (click)="openTycs()" title="tyc">Términos y Condiciones</span>
        </label>
        <button [disabled]="!activeBtn" class="block btn start" [ngClass]="{'disabled': !activeBtn}" (click)="goToVC()"> Comenzar Video Consulta</button>     

    </div>

</div>
<app-footer></app-footer>